import { ValidationError, useForm } from "@formspree/react";
import React, { useContext, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CartContext from "../context/CartContext";

function PopupFormCalculator({
	formPropertyName,
	subject,
	type,
	priceFrom,
	priceTo,
	onSuccess,
}) {
	const [state, handleSubmit] = useForm("xpzeyokp");
	const { numberOfDesksCalculator } = useContext(CartContext);
	const [step, setStep] = useState(1);

	const [formData, setFormData] = useState({
		subject: subject,
		location: formPropertyName,
		name: "",
		workEmail: "",
		company: "",
		numberOfDesks: `${numberOfDesksCalculator} Desk${
			numberOfDesksCalculator > 1 ? "s" : ""
		}`,
		type: type,
		calculatedPriceFrom: `£${priceFrom}`,
		calculatedPriceTo: `£${priceTo}`,
		message: "",
		acceptPolicy: false,
	});

	const formGroupStyles = "mb-2";
	const formInputStyles = "p-2 ps-3";

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const nextStep = async () => {
		if (step === 1 && !formData.workEmail) {
			alert("Please enter your Work Email");
			return;
		}

		if (step === 1 && formData.workEmail) {
			try {
				const response = await fetch("/.netlify/functions/submitEmail", {
					method: "POST",
					body: JSON.stringify({ email: formData.workEmail }),
				});
				const result = await response.json();
				if (!response.ok) {
					alert(result.message);
					return;
				}
			} catch (error) {
				alert("Error submitting email");
				return;
			}
		}

		if (step === 2) {
			if (!formData.name || !formData.company) {
				alert("Please fill out all required fields");
				return;
			}
		}

		setStep(step + 1);
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (!formData.acceptPolicy) {
			alert("Please accept the privacy policy");
			return;
		}
		handleSubmit(e);
	};

	useEffect(() => {
		if (state.succeeded) {
			if (onSuccess) {
				onSuccess();
			}
		}
	}, [state.succeeded, onSuccess]);

	const getFieldStyle = (fieldStep) => {
		return step === fieldStep ? {} : { display: "none" };
	};

	return (
		<Form className="w-100" onSubmit={onSubmit}>
			{/* Hidden Fields */}
			<Form.Group className={`${formGroupStyles} d-none`} controlId="subject">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					value={formData.subject}
					name="subject"
					type="text"
					className={`${formInputStyles}`}
					readOnly
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />

			<Form.Group className={`${formGroupStyles} d-none`} controlId="location">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					value={formData.location}
					name="location"
					type="text"
					className={`${formInputStyles}`}
					readOnly
				/>
			</Form.Group>
			<ValidationError
				prefix="Location"
				field="location"
				errors={state.errors}
			/>

			{/* Step 1: Name and Work Email */}
			<div style={getFieldStyle(1)}>
				<Row>
					<Col lg={12}>
						<Form.Group className={`${formGroupStyles}`} controlId="workEmail">
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								required={step === 1}
								name="workEmail"
								type="email"
								placeholder="Work Email *"
								className={`${formInputStyles}`}
								value={formData.workEmail}
								onChange={handleChange}
							/>
						</Form.Group>
						<ValidationError
							prefix="WorkEmail"
							field="workEmail"
							errors={state.errors}
						/>
					</Col>
				</Row>
			</div>

			{/* Step 2: Company and Number of Desks */}
			<div style={getFieldStyle(2)}>
				<Row>
					<Col lg={6}>
						{" "}
						<Form.Group className={`${formGroupStyles}`} controlId="name">
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								required={step === 2}
								name="name"
								placeholder={`Full Name *`}
								type="text"
								className={`${formInputStyles}`}
								value={formData.name}
								onChange={handleChange}
							/>
						</Form.Group>
						<ValidationError prefix="Name" field="name" errors={state.errors} />
					</Col>
					<Col lg={6}>
						<Form.Group className={`${formGroupStyles}`} controlId="company">
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								required={step === 2}
								type="text"
								name="company"
								placeholder="Company *"
								className={`${formInputStyles}`}
								value={formData.company}
								onChange={handleChange}
							/>
						</Form.Group>
						<ValidationError
							prefix="Company"
							field="company"
							errors={state.errors}
						/>
					</Col>
					<Form.Group
						className={`${formGroupStyles}`}
						controlId="numberOfDesks"
					>
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							name="numberOfDesks"
							type="text"
							value={formData.numberOfDesks}
							placeholder="Number Of Desks"
							className={`${formInputStyles}`}
							onChange={handleChange}
						/>
					</Form.Group>
					<ValidationError
						prefix="numberOfDesks"
						field="numberOfDesks"
						errors={state.errors}
					/>
				</Row>
			</div>

			{/* Step 3: Message, Prices, and Accept Policy */}
			<div style={getFieldStyle(3)}>
				<Form.Group className={`${formGroupStyles}`} controlId="message">
					<Form.Control
						style={{ border: "1px solid #E3E3E3" }}
						name="message"
						placeholder="Any additional requirements?"
						as="textarea"
						rows={3}
						className={`${formInputStyles}`}
						value={formData.message}
						onChange={handleChange}
					/>
				</Form.Group>
				<ValidationError
					prefix="Message"
					field="message"
					errors={state.errors}
				/>

				<Row className="d-none">
					<Col lg={6}>
						<Form.Group className={`${formGroupStyles}`} controlId="Type">
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								name="Type"
								type="text"
								value={formData.type}
								placeholder="Selected Card"
								className={`${formInputStyles}`}
								readOnly
							/>
						</Form.Group>
						<ValidationError prefix="Type" field="Type" errors={state.errors} />
					</Col>
					<Col lg={3}>
						<Form.Group
							className={`${formGroupStyles}`}
							controlId="calculatedPriceFrom"
						>
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								name="calculatedPriceFrom"
								type="text"
								value={formData.calculatedPriceFrom}
								placeholder="calculatedPriceFrom"
								className={`${formInputStyles}`}
								readOnly
							/>
						</Form.Group>
						<ValidationError
							prefix="calculatedPriceFrom"
							field="calculatedPriceFrom"
							errors={state.errors}
						/>
					</Col>
					<Col lg={3}>
						<Form.Group
							className={`${formGroupStyles}`}
							controlId="calculatedPriceTo"
						>
							<Form.Control
								style={{ border: "1px solid #E3E3E3" }}
								name="calculatedPriceTo"
								type="text"
								value={formData.calculatedPriceTo}
								placeholder="Price To"
								className={`${formInputStyles}`}
								readOnly
							/>
						</Form.Group>
						<ValidationError
							prefix="calculatedPriceTo"
							field="calculatedPriceTo"
							errors={state.errors}
						/>
					</Col>
				</Row>

				<Form.Group className="mb-2" controlId="acceptPolicy">
					<Form.Check
						required={step === 3}
						label={
							<span>
								I accept the{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href="https://app.termly.io/document/privacy-policy/d479506b-530d-4b78-afbf-949d899b4bd5"
								>
									privacy policy
								</a>
							</span>
						}
						className="w-auto"
						type="checkbox"
						name="acceptPolicy"
						checked={formData.acceptPolicy}
						onChange={handleChange}
					/>
				</Form.Group>
				<ValidationError
					prefix="acceptPolicy"
					field="acceptPolicy"
					errors={state.errors}
				/>
			</div>

			{/* Navigation Buttons */}
			<div className="d-md-flex justify-content-between mt-3">
				{step > 1 && (
					<Button
						size="small"
						className="btn w-100 mb-2 mb-md-0 w-md-auto btn-primary text-uppercase text-white px-6 rounded-pill"
						type="button"
						onClick={prevStep}
					>
						Back
					</Button>
				)}
				{step < 3 && (
					<Button
						size="small"
						className="btn btn-darker-blue w-100 w-md-auto text-uppercase ms-auto text-white px-6 rounded-pill"
						type="button"
						onClick={nextStep}
					>
						Next
					</Button>
				)}
				{step === 3 && (
					<Button
						size="small"
						className="btn btn-darker-blue w-100 w-md-auto text-white px-6 rounded-pill"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						SUBMIT
					</Button>
				)}
			</div>
		</Form>
	);
}

export default PopupFormCalculator;
